import React from 'react';
import classes from './Footer.module.css';
import {NavLink} from 'react-router-dom';

const footer = () => (
  <footer className={classes.Footer}>
    <NavLink className={classes.links} to="/impressum" activeClassName={classes.active}>Impressum</NavLink>
    <NavLink className={classes.links} to="/datenschutz" activeClassName={classes.active}>Datenschutz</NavLink>
  </footer>
);

export default footer;
