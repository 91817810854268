import React, {Component} from 'react';
import classes from './Contact.module.css';

class Contact extends Component{
  state = {

  }

  render(){

    let h1 = null;

    let name = null;
    let surname = null;
    let email = null;
    let organisation = null;
    let message = null;
    let submit = null;

    switch(this.props.lang){
      case 'german': ;
        h1 = 'Kontakt';
        name = 'Vorname';
        surname = 'Nachname';
        email = 'E-Mail Adresse';
        organisation = 'Organisation';
        message = 'Nachricht';
        submit = 'Senden';
      break;

      case 'english': ;
        h1 = 'Contact';
        name = 'name';
        surname = 'surname';
        email = 'e-mail address';
        organisation = 'organisation';
        message = 'message';
        submit = 'send';
      break;

      case 'french':
        h1 = 'Contact';
        name = 'Prénom';
        surname = 'Nom';
        email = 'adresse email';
        organisation = 'organisation';
        message = 'message';
        submit = 'Envoyer   ';
      break;
      default: h1 = 'Kontakt';
      name = 'Vorname';
      surname = 'Nachname';
      email = 'E-Mail Adresse';
      organisation = 'Organisation';
      message = 'Nachricht';
      submit = 'Senden';
    }


    return(
      <div>
        <div id="contact" className={classes.Contact}>
          <div className={classes.Content}>
            <div className={classes.Header}>
              <h1>{h1}</h1>
            </div>
            <div className={classes.Form}>
              <form accept-charset="UTF-8" action="https://usebasin.com/f/14957b679e6f" method="POST">
                <input className="name" placeholder={name} name="name" type="text"/>
                <input className="surname" placeholder={surname} name="surname" type="text"/>
                <input id="email  " className="email" placeholder={email} name="email" type="text"/>
                <input className="organisation" placeholder={organisation + '*'} name="organisation" type="text"/>
                <textarea placeholder={message} rows="6" name="message" id="" ></textarea>
                <input value={submit} className="submit" type="submit"/>
              </form>
            </div>
          </div>
        </div>
        <div className={classes.Plas}></div>
      </div>
    );
  }
}

export default Contact;
