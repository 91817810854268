import React, { Component } from 'react';
import './App.css';

import Header from './components/Header/Header';
import Hero from './components/Hero/Hero';
import AboutMe from './components/AboutMe/AboutMe';
import Skills from './components/Skills/Skills';
import Contact from './components/Contact/Contact';
import Footer from './components/Footer/Footer';
import Impressum from './components/Impressum/Impressum';
import Datenschutz from './components/Datenschutz/Datenschutz';

import {Route} from 'react-router-dom';
import Favicon from 'react-favicon';
import Logo from './assets/pictures/AJSquare.jpg';

class App extends Component {
  state = {
    language: {value: 'german', label: 'Deutsch'}
  }

  onLanguageChange = (value) =>{
    this.setState({language: value});
  }




  render() {
    let languageID = null;
    switch(this.state.language.value){
      case 'german': languageID = 'german'; break;
      case 'english': languageID = 'english'; break;
      case 'french': languageID = 'french'; break;
      default: languageID = 'german';
    }

    return (
      <div>
        <Favicon url={Logo}/>
        <Route exact path="/" render={() => (
          <>
            <Header lang={languageID} change={this.onLanguageChange} language={this.state.language}/>
            <Hero/>
            <AboutMe lang={languageID}/>
            <Skills lang={languageID}/>
            <Contact lang={languageID}/>
          </>
        )}
          
         />   
        <Route exact path="/impressum" component={Impressum}/>
        <Route exact path="/datenschutz" component={Datenschutz} />
        <Footer />
      </div>
    );
  }
}

export default App;
