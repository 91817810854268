import React from 'react';
import AnassSchwarz from '../../assets/pictures/slice2.png';
import {Grid, Row, Col} from 'react-bootstrap';
import classes from './AboutMe.module.css';

const aboutMe = (props) => {
  let text1 = null;
  let text2 = null;
  let text3 = null;
  let h1 = null;

  switch(props.lang){
    case 'german':
      h1 = 'Über mich';
      text1 = 'Moin! Ich bin Anass Jdaâ, 26 Jahre alt aus Kiel.';
      text2 = 'Ich studiere Wirtschaftsinformatik an der FH Kiel mit dem Schwerpunkt Projektmanagement und arbeite als Quality Engineer bei BSPayone';
      text3 = 'Ich würde gerne meine Fähigkeiten verbessern und interessiere mich für spannende Kontakte.';
    break;

    case 'english':
      h1 = 'About me';
      text1 = 'Hey! I am Anass Jdaâ, 26 years old from Kiel in germany.';
      text2 = 'I am studying Information Systems at FH Kiel and I am working meanwhile as a Quality Engineer for BSPayone.';
      text3 = 'I am interested in expanding my professional horizons.';
    break;

    case 'french':
      h1 = 'Biographie';
      text1 = 'Salut! Je suis Anass Jdaâ, 26 ans. Je viens de Kiel en allemagne.';
      text2 = 'Je suis étudiant de systemes d´information à FH Kiel et je travaille parallèlement en tant qu`ingénieur de qualité à BSPayone.';
      text3 = 'J´aimerais bien enrichir mes compétences et je m´intéresse à développer des relations professionnelles sur le long terme.';
    break;
    default: h1 = 'Über mich';
    text1 = 'Moin! Ich bin Anass Jdaâ, 26 Jahre alt aus Kiel.';
    text2 = 'Ich studiere Wirtschaftsinformatik an der FH Kiel mit dem Schwerpunkt Projektmanagement und arbeite als Quality Engineer bei BSPayone';
    text3 = 'Ich würde gerne meine Fähigkeiten verbessern und interessiere mich für spannende Kontakte.';

  }


  return (
  <div id="about" className={classes.AboutMe}>
    <div className={classes.Content}>
      <div className={classes.Header}>
        <h1>{h1}</h1>
        <img src={AnassSchwarz} alt=""/>
      </div>
      <div className={classes.Text}>
        <Grid>
          <Row>
            <Col xl={4} lg={4} md={4} sm={12} xs={12}>
              <p>{text1}</p>
            </Col>
            <Col xl={4} lg={4} md={4} sm={12} xs={12}>
              <p>{text2}</p>
            </Col>
            <Col xl={4} lg={4} md={4} sm={12} xs={12}>
              <p>{text3}</p>
            </Col>
          </Row>
        </Grid>
      </div>
    </div>
  </div>
);
};

export default aboutMe;
