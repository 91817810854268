import React from 'react';
import classes from './Hero.module.css';
import Anass from '../../assets/pictures/slice1.png';
import Graphic from '../../assets/pictures/undrawGraphic.png';

const hero = () => (
  <div id="home" className={classes.Hero}>
    <div className={classes.Anass}>
      <img src={Anass} alt="Anass Jdaa"/>
      <h1>Anass<br/><span>Jdaâ</span></h1>
    </div>
    <div className={classes.Graphic}>
      <img src={Graphic} alt="Developer"/>
    </div>
  </div>
);

export default hero;
