import React from 'react';
import classes from './Skills.module.css';
import Card from './Card/Card';
import {Grid, Row, Col} from 'react-bootstrap';

const skills = (props) => {
  let h1 = null;

  switch(props.lang){
    case 'german': h1 = 'Fähigkeiten'; break;
    case 'english': h1 = 'Skillset'; break;
    case 'french': h1 = 'Compétences'; break;
    default: h1 = 'Fähigkeiten';

  }

  return (
  <div id="skills" className={classes.Skills}>
    <div className={classes.Content}>
      <div className={classes.Header}>
        <h1>{h1}</h1>
      </div>
      <div className={classes.Cards}>
        <Grid>
          <Row>
            <Col xl={4} bg={4} md={4} sm={6} xs={12}> <Card skill="QMB ISO 9001:2015"/> </Col>
            <Col xl={4} bg={4} md={4} sm={6} xs={12}> <Card skill="SAP/ERP"/> </Col>
            <Col xl={4} bg={4} md={4} sm={12} xs={12}> <Card skill="SPSS"/> </Col>
          </Row>
          <Row>
            <Col xl={4} bg={4} md={4} sm={6} xs={12}> <Card skill="Agiles Projektmanagement"/> </Col>
            <Col xl={4} bg={4} md={4} sm={6} xs={12}> <Card skill="PowerDesigner"/> </Col>
            <Col xl={4} bg={4} md={4} sm={12} xs={12}> <Card skill="Java / Testautomation"/> </Col>
          </Row>
        </Grid>
      </div>
    </div>
  </div>
);
};

export default skills;
