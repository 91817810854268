import React,{Component} from 'react';
import AnchorLink from 'react-anchor-link-smooth-scroll';
import ResponsiveMenu from 'react-responsive-navbar';

import Open from '../../assets/UI/Open.png';
import Close from '../../assets/UI/Close.png';
import Square from '../../assets/pictures/AJSquare.jpg';
import classes from './Header.module.css';
import Select from 'react-select';

const options = [
  {value: 'german', label: 'Deutsch'},
  {value: 'english', label: 'English'},
  {value: 'french', label: 'Français'}
]


class Header extends Component{


  render(){
    let home = null;
    let about = null;
    let skills = null;
    let contact = null;

    switch(this.props.lang){
      case 'german':
        home = 'Home';
        about = 'Über mich';
        skills = 'Fähigkeiten';
        contact = 'Kontakt';
      break;

      case 'english':
        home = 'Home';
        about = 'About me';
        skills = 'Skillset';
        contact = 'Contact';
      break;

      case 'french':
        home = 'Accueil';
        about = 'Biographie';
        skills = 'Compétences';
        contact = 'Contact';
      break;
      default:
      home = 'Home';
      about = 'Über mich';
      skills = 'Fähigkeiten';
      contact = 'Kontakt';

    }


    return(
      <div>
        <img className={classes.Logo} src={Square} alt="Anass Jdaa"/>
        <ResponsiveMenu
          menuOpenButton={<div className={classes.Toggle}><img src={Open} alt=""/></div>}
          menuCloseButton={<div className={classes.ToggleCloser}><img src={Close} alt=""/></div>}
          changeMenuOn="1100px"
          largeMenuClassName={classes.Header}
          smallMenuClassName={classes.HeaderDevice}
          menu={
            <ul>
              <li><a rel="noopener noreferrer" target="_blank" href="https://www.xing.com/profile/Anass_Jdaa/cv?sc_o=mxb_p">XING</a></li>
              <li><a rel="noopener noreferrer" target="_blank" href="https://www.linkedin.com/in/anass-jda%C3%A2-06003548/">Linked<span>In</span></a></li>
              <li><a rel="noopener noreferrer" target="_blank" href="https://blog.anassjdaa.com"><span>AJ</span>Blog</a></li>
              <li> <Select onChange={this.props.change} value={this.props.language} options={options}/> </li>
              <li><AnchorLink href="#home">{home}</AnchorLink></li>
              <li><AnchorLink href="#about">{about}</AnchorLink></li>
              <li><AnchorLink offset='100' href='#skills'>{skills}</AnchorLink></li>
              <li><AnchorLink offset='100' href='#contact'>{contact}</AnchorLink></li>
            </ul>

          }
        />
      </div>
    );
  }
}

export default Header;
